let key = {};
let IPFS_IMG = "https://ipfs.io/ipfs/";
// let IPFS_IMG = "https://naifty.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
var EnvName = "clientStage";
var networkTransUrl = ''

var Front_market_Url = ''

if (EnvName === "local") {
  Back_Url = "http://localhost:3030/v1/admin";
  Front_market_Url = "http://localhost:3000/naiftyadmin";
  var image_url = "http://localhost:3030/token";
  ImG = "http://localhost:3030";
  var singleAddress = "0x575cd9E4099A38B536673F557063f9A546870d11"; // seplia
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0x61"
  var chainId = 84532;
  var ETHCHAIN = 84532
  var BNBCHAIN = 97
  var chainlist = [97, 84532]
  var versions = ["0x61", "0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x5b6AaF50abD17EA1faE897c487B71CDf75d90769'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://base-sepolia.infura.io/v3/8bdcb041261546d0b6b11c4e3876c2f4";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "http://localhost:3030/v1";
  var COIN_NAME = "ETH"
  networkTransUrl = "https://sepolia.etherscan.io/tx/"
  // var tradeAddress = "0x31C6551011Ce7dF74Cc2F26B81F7C5728690e5A5".toLowerCase()  //basesepolia
  // var stakeAddress = "0xEb017D100bA6d56810a3dBD4007455B0Ffd69D19".toLowerCase()  // removed openzep bnb

  // var tradeAddress = "0xe12ABc1BE286a2D774d91fF9426A3347E43b768E".toLowerCase()  // audit test 11/03/25
  // var stakeAddress = "0x7EA1B402a08715dfD95Dc37326BFba4dB87678Fb".toLowerCase()  // audit test 11/03/25

  var tradeAddress = "0x1E9b2E7f96c3dc797f324bc6666AA2039165e167".toLowerCase()  //  27/03/25
  var stakeAddress = "0x6258CEBffBE68954a9e1bdACac7631212c686bd3".toLowerCase()  //  11/03/25

}

if (EnvName === "clientStage") {
  Back_Url = "https://staging-backend.homecubes.io/v1/admin";
  Front_market_Url = "https://localhost:3000/naiftyadmin";
  var image_url = "https://staging-backend.homecubes.io/token";
  ImG = "https://staging-backend.homecubes.io";

  var singleAddress = "0x575cd9E4099A38B536673F557063f9A546870d11"; // seplia
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0x61"
  var chainId = 84532;
  var ETHCHAIN = 84532
  var BNBCHAIN = 97
  var chainlist = [97, 84532]
  var versions = ["0x61", "0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x5b6AaF50abD17EA1faE897c487B71CDf75d90769'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://base-sepolia.infura.io/v3/8bdcb041261546d0b6b11c4e3876c2f4";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "https://staging-backend.homecubes.io/v1";
  var COIN_NAME = "BASE"
  networkTransUrl = "https://sepolia.basescan.org/tx/"
  // var stakeAddress = "0x24A66c05d357821032970E90AC3B7fF2bc828356" // removed openzep bnb
  // var tradeAddress = "0xc6aBa068A91d327B259327523f71f51221943186".toLowerCase()  //basesepolia
  var tradeAddress = "0x1E9b2E7f96c3dc797f324bc6666AA2039165e167".toLowerCase()  //  27/03/25
  var stakeAddress = "0x6258CEBffBE68954a9e1bdACac7631212c686bd3".toLowerCase()  //  11/03/25

}

if (EnvName === "stage") { // sales

  Back_Url = "https://homecubesbackend.maticz.in/v1/admin";
  Front_market_Url = "https://homecube.maticz.in/";
  var image_url = "http://localhost:3030/token";
  ImG = "https://homecubesbackend.maticz.in";
  var tradeAddress = "0xeA7AE9464eF470DBbD43488F0875FD54521576b1".toLowerCase(); // seplia openzep removed
  var singleAddress = "0x575cd9E4099A38B536673F557063f9A546870d11"; // seplia
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0x61"
  var chainId = 97;
  var ETHCHAIN = 11155111
  var BNBCHAIN = 97
  var chainlist = [97, 11155111]
  var versions = ["0x61", "0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x5b6AaF50abD17EA1faE897c487B71CDf75d90769'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://eth-sepolia.g.alchemy.com/v2/demo";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  // var Back_Url_Token = "https://naifty.io/v1";
  var Back_Url_Token = "https://homecubesbackend.maticz.in/v1";
  var COIN_NAME = "BNB"
  networkTransUrl = "https://sepolia.etherscan.io/tx/"
  var stakeAddress = "0x966D215D56b06E3469Df0D9fD80A5cB78f9A1EAA"

}

if (EnvName === "demo") {

  Back_Url = "https://homecubes-backend.maticz.in/v1/admin";
  Front_market_Url = "https://home-cubes-frontend-2.pages.dev/";
  var image_url = "http://localhost:3030/token";
  ImG = "https://homecubes-backend.maticz.in/";

  var singleAddress = "0x575cd9E4099A38B536673F557063f9A546870d11"; // seplia
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0x61"
  var chainId = 84532;
  var ETHCHAIN = 84532
  var BNBCHAIN = 97
  var chainlist = [97, 84532]
  var versions = ["0x61", "0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x5b6AaF50abD17EA1faE897c487B71CDf75d90769'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://base-sepolia.infura.io/v3/8bdcb041261546d0b6b11c4e3876c2f4";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "http://localhost:3030/v1";
  var COIN_NAME = "ETH"
  networkTransUrl = "https://sepolia.etherscan.io/tx/"
  var tradeAddress = "0x31C6551011Ce7dF74Cc2F26B81F7C5728690e5A5".toLowerCase()  //basesepolia
  var stakeAddress = "0xEb017D100bA6d56810a3dBD4007455B0Ffd69D19".toLowerCase()  // removed openzep bnb

  // var tradeAddress = "0xe12ABc1BE286a2D774d91fF9426A3347E43b768E".toLowerCase()  // audit test 11/03/25
  // var stakeAddress = "0x7EA1B402a08715dfD95Dc37326BFba4dB87678Fb".toLowerCase()  // audit test 11/03/25

  var tradeAddress = "0x04805F34FDDBf8e898DFcff7Cf70C19653767d3E".toLowerCase()  //  27/03/25
  var stakeAddress = "0x7EA1B402a08715dfD95Dc37326BFba4dB87678Fb".toLowerCase()  //  11/03/25


}

if (EnvName === "production") {
  Back_Url = "https://api.homecubes.io/v1/admin";
  Front_market_Url = "https://app.homecubes.io/";
  var image_url = "http://localhost:3030/token";
  ImG = "https://api.homecubes.io";
  var tradeAddress = "0x06634158F0a6fdb7686b67d128d5b8d498349cD9".toLowerCase()  // audit bsc testnet

  var singleAddress = "0x575cd9E4099A38B536673F557063f9A546870d11"; // seplia
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0x61"
  var chainId = 11155111;
  var ETHCHAIN = 11155111
  var BNBCHAIN = 97
  var chainlist = [97, 11155111]
  var versions = ["0x61", "0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x5b6AaF50abD17EA1faE897c487B71CDf75d90769'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://eth-sepolia.g.alchemy.com/v2/demo";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  // var Back_Url_Token = "https://naifty.io/v1";
  var Back_Url_Token = "https://api.homecubes.io/v1";
  networkTransUrl = "https://sepolia.etherscan.io/tx/"
  var COIN_NAME = "BNB"
  var stakeAddress = "0x966D215D56b06E3469Df0D9fD80A5cB78f9A1EAA"
}

key = {
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBprovider,
  ETHprovider: ETHprovider,
  ETHCHAIN: ETHCHAIN,
  BNBCHAIN: BNBCHAIN,
  chainlist: chainlist,
  bnbver: BNBversion,
  ethver: ETHversion,
  versionlist: versions,
  ENVname: EnvName,
  networkTransUrl: networkTransUrl,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  ETHTRADE: ETHTRADE,
  BNBTRADE: BNBTRADE,
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  AdminAddress: "0x025c1667471685c323808647299e5DbF9d6AdcC9".toLowerCase(),
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url: Front_market_Url,
  networkVersion: networkVersion,
  KEY: 'MNBVCZX1234)(*',
  NumDigitOnly: /[^0-9\.]/g,
  COIN_NAME: COIN_NAME,
  stakeAddress: stakeAddress,
  DEADADDRESS: '0x000000000000000000000000000000000000dEaD'.toLowerCase()
};

export default key;
